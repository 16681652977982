<template>
	<div class="template__settings">
		<div class="template__header">
			<div class="header__button" @click="goToTemplateMenu">
			    <span class="iconify" data-icon="uil:times" data-width="25" data-height="25"></span>
			</div>
            <div class="header__title" >
				Template settings
            </div>
			<div class="header__button" @click="submit">
				<span class="iconify" data-icon="uil:check" style="color: #0075ff;" data-width="25" data-height="25"></span>
			</div>
		</div>
		<div class="template" v-if="originTemplate">
			<div class="template__title-container" v-bind:style="{
				'background' : templateBackground(originTemplate, 1)
			}">
				<div class="template__title">{{originTemplate.title || "Template"}}</div>
			</div>
	
			<div class="template__properties"	v-bind:style="{
				'border-color' : templateBackground(originTemplate, 0.5)
			}">
				<SlotSettingsForm v-model="form" :disableList="disableList"/>
			</div>
		</div>
	</div>
</template>

<script>
import { addOpacityToColor } from '../../../scripts/color';
import SlotSettingsForm from '../../forms/slot-settings/SlotSettingsForm.vue';
import SlotSettingsFormInstance from '../../forms/slot-settings/formInstance';
import router from '../../../router';

export default {
	name: 'TemplateSettings',
	components: {
		SlotSettingsForm
	},
	computed: {
		originTemplate(){
			let templateId = this.$route.params.templateId
			let origin = this.$store.getters.calendarSlotTemplateById(templateId)
			if (origin){
				this.form = new SlotSettingsFormInstance(JSON.parse(JSON.stringify(origin)))
			}
			return origin
		}
	},
	data() {
		return {
			form: {},
			disableList: [
				'template',
				'date',
				'time',
				'timezone-difference',
			]
		};
	},
	// created(){	
	// 	this.$store.dispatch('setup-slot-templates')
	// },
	

	methods: {
		addOpacityToColor: addOpacityToColor,
		goToTemplateMenu(){
			router.push({name:'calendar-settings-template-menu'})
		},
		templateBackground(template, opacity) {
			if (!template || !template.cellBackgroundColor)
				return "lavender"
			let isRGB = template.cellBackgroundColor.includes("rgb")
			let isHex = template.cellBackgroundColor.includes("#")
			if (isRGB || isHex)
				return addOpacityToColor(template.cellBackgroundColor, opacity)
			return template.cellBackgroundColor
		},
		submit(){

		}
	}
};
</script>

<style scoped>

.template{
	margin: 15px 5px 0 5px;
	animation: fade .3s;
	
}
.template__title-container{
	padding: 7px 6px;
}

.template__properties{
	height:	calc(100vh - 32px - 50px - 80px);
	border: 2px solid;
	border-top: none;
}

.template__header {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    padding: 2px 5px;

    background: lightgrey;

	display: grid;
	grid-template-columns: 26px calc(100% - 26px - 16px - 26px - 16px ) 26px;
	grid-column-gap: 16px;
}

.header__title {
    padding: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header__button{
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>